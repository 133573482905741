
import { Student } from '@/models/students/student.class';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EntityList from '@/components/EntityList.vue';
import { EntityListColumn, EntityListRowAction } from '../models/shared/entity-list.interfaces';
import { UserRole } from '@/models/users/user-role.enum';
import { User } from '@/models/users/user.class';
import { cloneDeep } from 'lodash';
import { BModal } from 'bootstrap-vue/src/components/modal';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import { FilterOperation } from '@/models/app/filter-operation.enum';
import { Customer } from '@/models/customers/customer.class';
import StudentForm from '@/components/StudentForm.vue';
import api from '@/api/api';
import Multiselect from 'vue-multiselect';

const registrationModule = namespace('registration');
const authModule = namespace('auth');

interface Certificate {
  sessionId: string;
}

@Component({
  components: { EntityList, StudentForm, Multiselect },
})
export default class Participants extends Vue {
  @registrationModule.Action('fetchStudents')
  fetchStudents!: (filter?: FetchAllParams) => Promise<Student[]>;

  @registrationModule.Action('createStudent')
  createStudent!: (student: Student) => Promise<Student>;

  @registrationModule.Action('updateStudent')
  updateStudent!: (student: Student) => Promise<Student>;

  @registrationModule.Action('removeStudent')
  removeStudent!: (student: Student) => Promise<void | string>;

  @authModule.Getter('authenticatedUser')
  authenticatedUser!: User;

  $refs!: Vue['$refs'] & {
    removeStudentModal: BModal;
    addStudentModal: BModal;
    editStudentModal: BModal;
    certificateModal: BModal;
  };

  studentToBeRemoved: Student | null = null;
  studentToCreate: Student | null = null;

  customerStudents: Student[] = [];

  isCreatingStudent = false;

  saveStudentIsLoading = false;
  updateStudentIsLoading = false;

  editingStudent: Student | null = null;

  errorTranslationKey = '';

  isLoading = false;

  get listColumns(): Array<EntityListColumn<Student>> {
    return [
      {
        field: 'firstName',
        label: `${this.$t('forms.firstName')}`,
      },
      {
        field: 'lastName',
        label: `${this.$t('forms.lastName')}`,
      },
      {
        field: 'email',
        label: `${this.$t('forms.email')}`,
      },
      {
        field: 'phone',
        label: `${this.$t('forms.phone')}`,
      },
    ];
  }

  get certificateListColumns(): Array<EntityListColumn<Student>> {
    return [
      {
        field: 'courseName',
        label: '',
      },
    ];
  }

  get listActions(): Array<EntityListRowAction<Student>> {
    return [
      {
        key: 'edit',
        label: `${this.$t('general.edit')}`,
        showAction: () => this.canEditStudents,
        doAction: (action, item) => this.showEditStudentModal(item),
      },
      {
        key: 'delete',
        label: `${this.$t('general.delete')}`,
        showAction: () => true,
        doAction: (action, item) => this.showRemoveStudentModal(item),
      },
      {
        key: 'certificate',
        label: `${this.$t('account.certificates')}`,
        showAction: () => true,
        doAction: (action, item) => this.showStudentCertificateModal(item),
      },
    ];
  }

  get certificateListActions(): Array<EntityListRowAction<Certificate>> {
    return [
      {
        key: 'download',
        label: `${this.$t('general.downLoad')}`,
        showAction: () => true,
        doAction: (action, item) => this.downloadCertificate(item),
      },
    ];
  }
  get canEditStudents() {
    return this.authenticatedUser.role === UserRole.ADMIN;
  }

  showRemoveStudentModal(studentToRemove: Student) {
    this.studentToBeRemoved = cloneDeep(studentToRemove);
    this.$refs.removeStudentModal.show();
  }

  async removeStudentConfirmed() {
    this.customerStudents = this.customerStudents.filter(
      student => student._id !== (this.studentToBeRemoved as Student)._id,
    );
    const response = await this.removeStudent(this.studentToBeRemoved as Student);
    if (typeof response === 'string') {
      this.errorTranslationKey = response;
    }
  }

  onRemoveStudentModalClosing() {
    this.studentToBeRemoved = null;
  }

  showAddStudentModal(studentToCreate: Student) {
    this.studentToCreate = studentToCreate;
    this.$refs.addStudentModal.show();
  }

  onAddStudentModalClosing() {
    this.studentToCreate = null;
    this.isCreatingStudent = false;
  }

  onCancelCreateStudent() {
    this.isCreatingStudent = false;
    this.studentToCreate = null;
    this.$refs.addStudentModal.hide();
  }

  async onSaveStudent() {
    this.saveStudentIsLoading = true;

    if (this.studentToCreate) {
      if (this.authenticatedUser.customer && typeof this.authenticatedUser.customer !== 'string') {
        this.studentToCreate.customer = this.authenticatedUser.customer._id as string;
      }

      const createdStudent = await this.createStudent(this.studentToCreate);
      if (createdStudent) {
        this.isCreatingStudent = false;
        this.studentToCreate = null;
        this.customerStudents.push(createdStudent);
        this.$refs.addStudentModal.hide();
      }
    }

    this.saveStudentIsLoading = false;
  }

  showEditStudentModal(studentToEdit: Student) {
    this.editingStudent = cloneDeep(studentToEdit);
    this.$refs.editStudentModal.show();
  }

  onCancelEditStudent() {
    this.$refs.editStudentModal.hide();
  }

  onEditStudentModalClosing() {
    this.editingStudent = null;
  }

  async onUpdateStudent() {
    this.updateStudentIsLoading = true;

    if (this.editingStudent) {
      const updatedStudent: Student = await this.updateStudent(this.editingStudent);
      if (updatedStudent) {
        const index = this.customerStudents.findIndex(
          student => student._id === updatedStudent._id,
        );
        this.customerStudents.splice(index, 1, updatedStudent);
        this.$refs.editStudentModal.hide();
      }
    }

    this.updateStudentIsLoading = false;
  }

  async fetchStudentsByCustomer() {
    const filter: FetchAllParams = {
      filters: {
        customer: {
          value: [(this.authenticatedUser.customer as Customer)._id],
          operation: FilterOperation.Equals,
        },
      },
    };
    this.customerStudents = await this.fetchStudents(filter);
  }

  showStudentCertificateModal(student: Student) {
    this.editingStudent = student;
    this.$refs.certificateModal.show();
  }

  async downloadCertificate(certificate: Certificate) {
    this.isLoading = true;
    const response = await api({
      url: `/students/getCertificate/${this.editingStudent?._id}/${certificate.sessionId}`,
      method: 'GET',
      responseType: 'blob',
    });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = certificate.sessionId;
    link.click();
    URL.revokeObjectURL(link.href);
    this.isLoading = false;
  }

  async created() {
    await this.fetchStudentsByCustomer();
  }
}
